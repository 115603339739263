@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100vh;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

body::-webkit-scrollbar {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

#root {
  height: 100vh;
}
/* Chat */
#chat-label,
#emojis-content {
  line-break: strict;
  text-indent: 10px;
  z-index: 3;
  position: fixed;
  /*border: 1px solid rebeccapurple;*/
  width: 100%;
}
#chat-label {
  top: 3em;
  padding-left: 6px;
  height: 3em;
  text-align: justify;
  position: absolute;
  width: 308px;
  z-index: 2;
}
/** Chat box **/
#chat-input-container {
  width: 250px;
  min-height: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 21px;
}

.chat-input-text-group {
  position: relative;
}

.chat-placeholder {
  color: #a0a0a0;
  top: 0;
  pointer-events: none;
  user-select: none;
  position: absolute;
  opacity: 0;
  transition: 0.2s padding ease-in-out;
}

#chat-input-text {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  max-height: 100px;
  min-height: 20px;
  padding: 8px;
  position: absolute;
  outline: 0;
  transition: 0.2s padding ease-in-out;
  width: 100%;
  height: 32px;
  top: -2px;
}

#chat-input-text:empty + .chat-placeholder {
  opacity: 1;
  padding: 5px;
}

#chat-input-text:focus + .chat-placeholder {
  padding: 5px;
}

#chat-input-text:focus {
  padding: 5px;
}

/** Chat box **/

/* Chat */

/* Reactions */
#emojis-content {
  height: 40px;
}
.emoji {
  margin-right: 10px;
  text-align: center;
  transform: scale(0.5);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: text-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.emoji:hover {
  transform: scale(1) translatey(-20px);
  text-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}
.emoji:hover {
  transform: scale(1) translatey(-20px);
  text-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}
.emoji-thumbsup:before {
  content: "🙂";
}
.emoji-thumbsup:hover:before {
  content: "😄";
}
.emoji-clap:before {
  content: "🤲";
}
.emoji-clap:hover:before {
  content: "👏";
}
.emoji-sunglasess:before {
  content: "🤓";
}
.emoji-sunglasess:hover:before {
  content: "😎";
}
.emoji-raised_hands:before {
  content: "🙌";
}
.emoji-raised_hands:hover:before {
  content: "🔥";
}
.emoji-open_mount:before {
  content: "😯";
}
.emoji-open_mount:hover:before {
  content: "😲";
}
/* Reactions */
